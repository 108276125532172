const intro = {
  en: {
    marqueeHeader: "Experience everywhere your card can take you",
    dropdown: "See where to use your card",
    travelGuidesHeader: "Explore popular merchants in top travel destinations",
    travelGuidesSub: "See where to use your card around the world.",
    acceptedHeader: "Accepted across millions of places around the world",
    acceptedSub:
      "From shopping and dining to hotels and transit and so many places in between.",
    viewMap: "View Map",
    viewGlobalMap: "View Global Acceptance Map",
    confidenceHdr: "Travel with confidence using your card",
    confidenceCopy: (
      <>
        <p>
          Your card is a part of the Discover Global Network and it's accepted
          at millions of stores, restaurants, hotels, and transit around the
          world.
        </p>
        <p>
          When shopping abroad, just confirm the acceptance marks on the back of
          your card appear at checkout.
        </p>
      </>
    ),
    bug: "Tap here to checkout the XX Travel Guide!",
    retail: "Retail",
    restaurants: "Dining",
    transportation: "Transportation",
    attractions: "Attractions",
    hotels: "Hotels",
    featuredMerchants: {
      header: "Recently added merchants",
      merchants: [
        {
          category: "Retail",
          title: "Armani Italy",
          city: "Italy",
          img: "Thumb_Armani_MarkSaxby_Unsplash.jpg",
        },
        {
          category: "Attractions",
          title: "The British Museum",
          city: "UK",
          img: "Thumb_BritishMuseum_SharissaJohnson_Unsplsh.jpg",
        },
        {
          category: "Transportation",
          title: "Corendon Airline",
          city: "Turkey",
          img: "Thumb_CorendonAirlines_ArthouseStudio_Pexels.jpg",
        },
        {
          category: "Dining",
          title: "Chambao",
          city: "Spain",
          img: "Thumb_Chambao_DecRecits_Unsplash.jpg",
        },
        {
          category: "Dining",
          title: "La Boucherie",
          city: "France",
          img: "Thumb_Laboucherie_JezTimm_Unsplash.jpg",
        },
        {
          category: "Attractions",
          title: "Park Warner Madrid",
          city: "Spain",
          img: "Thumb_ParqueWarner_DanaSredojevic_Pexels.jpg",
        },
        {
          category: "Attractions",
          title: "Scenic World Blue Mountains",
          city: "Australia",
          img: "Thumb_ScenicWorld_Malteluk_Pexels.jpg",
        },
        {
          category: "Retail",
          title: "Max Mara",
          city: "Hong Kong",
          img: "Thumb_MaxMara_PolinaTank_Pexels.jpg",
        },
        {
          category: "Hotels",
          title: "Bellustart Tokyo, A Pan Pacific Hotel",
          city: "Japan",
          img: "Thumb_BellustartTokyo_AndreaDavis_Unsplash.jpg",
        },
        {
          category: "Hotels",
          title: "Sofitel Krabi Phokeethra Golf and Spa Resort",
          city: "Thailand",
          img: "Thumb_SofitelGolfSpaResort_SaraDubler_Unsplash.jpg",
        },
        {
          category: "Hotels",
          title: "Raffles Hotel",
          city: "Singapore",
          img: "Thumb_Raffles_MilinJohn_Unsplash.jpg",
        },
        {
          category: "Attractions",
          title: "Hobbiton Movie Set Tours",
          city: "New Zealand",
          img: "Thumb_Hobbiton_JoshuaBayliss_Unsplash.jpg",
        },
      ],
    },
    featuredMerchantsAlt: {
      header: "Recently added merchants",
      merchants: [
        {
          title: "Featured Merchant Lorem",
          copy: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis excepteur sint.",
          image: "photo.jpg",
          cover: true,
          link: "http://google.com",
        },
        {
          title: "Featured Merchant Lorem",
          copy: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis excepteur sint.",
          image: "logo.png",
          cover: false,
          link: "http://google.com",
        },
        {
          title: "Featured Merchant Lorem",
          copy: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis excepteur sint.",
          image: "photo.jpg",
          cover: true,
          link: "http://google.com",
        },
      ],
    },
  },
  es: {
    marqueeHeader: "Disfrutá todas las oportunidades del mundo!",
    dropdown: "Consulta dónde usar tu tarjeta",
    travelGuidesHeader:
      "Descubrí nuestra red de comercios en los principales destinos de viaje",
    travelGuidesSub: "Conocé dónde usar tu tarjeta en todo el mundo",
    acceptedHeader: "Aceptado en millones de lugares alrededor del mundo.",
    acceptedSub:
      "Desde tiendas y restaurantes hasta hoteles y transporte público y muchos otros lugares.",
    viewMap: "Ver Mapa",
    viewGlobalMap: "Ver el Mapa de Aceptación Global",
    confidenceHdr: "Viaja con confianza usando tu tarjeta",
    confidenceCopy: (
      <>
        <p>
          Tu tarjeta es parte de la Red Global Discover y es aceptada en
          millones de tiendas, restaurantes, hoteles y medios de transporte
          alrededor del mundo.
        </p>
        <p>
          Cuando compres en el extranjero, solo asegúrate de que los signos de
          aceptación en el reverso de tu tarjeta aparezcan al momento de pagar.
        </p>
      </>
    ),
    bug: "¡Toque aquí para ver la Guía de Viajes de XX!",
    retail: "Compras",
    restaurants: "Comida",
    transportation: "Transporte",
    attractions: "Atracciones",
    hotels: "Hoteles",
    featuredMerchants: {
      header: "Comercios agregados recientemente",
      merchants: [
        {
          category: "Retail",
          title: "Armani Italia",
          city: "Italy",
          img: "Thumb_Armani_MarkSaxby_Unsplash.jpg",
        },
        {
          category: "Attractions",
          title: "El Museo Británico",
          city: "Reino Unido",
          img: "Thumb_BritishMuseum_SharissaJohnson_Unsplsh.jpg",
        },
        {
          category: "Transportation",
          title: "Aerolínea Corendon",
          city: "Turquía",
          img: "Thumb_CorendonAirlines_ArthouseStudio_Pexels.jpg",
        },
        {
          category: "Dining",
          title: "Chambao",
          city: "España",
          img: "Thumb_Chambao_DecRecits_Unsplash.jpg",
        },
        {
          category: "Dining",
          title: "La Boucherie",
          city: "Francia",
          img: "Thumb_Laboucherie_JezTimm_Unsplash.jpg",
        },
        {
          category: "Attractions",
          title: "Parque Warner Madrid",
          city: "España",
          img: "Thumb_ParqueWarner_DanaSredojevic_Pexels.jpg",
        },
        {
          category: "Attractions",
          title: "Scenic World Montañas Azules",
          city: "Australia",
          img: "Thumb_ScenicWorld_Malteluk_Pexels.jpg",
        },
        {
          category: "Retail",
          title: "Max Mara",
          city: "Hong Kong",
          img: "Thumb_MaxMara_PolinaTank_Pexels.jpg",
        },
        {
          category: "Hotels",
          title: "Bellustart Tokio, un hotel Pan Pacific",
          city: "Japón",
          img: "Thumb_BellustartTokyo_AndreaDavis_Unsplash.jpg",
        },
        {
          category: "Hotels",
          title: "Sofitel Krabi Phokeethra Golf and Spa Resort",
          city: "Tailandia",
          img: "Thumb_SofitelGolfSpaResort_SaraDubler_Unsplash.jpg",
        },
        {
          category: "Hotels",
          title: "Hotel Raffles",
          city: "Singapur",
          img: "Thumb_Raffles_MilinJohn_Unsplash.jpg",
        },
        {
          category: "Attractions",
          title: "Tours del Set de Película de Hobbiton",
          city: "Nueva Zelanda",
          img: "Thumb_Hobbiton_JoshuaBayliss_Unsplash.jpg",
        },
      ],
    },
    featuredMerchantsAlt: {
      header: "Comercios agregados recientemente",
      merchants: [
        {
          title: "Featured Merchant Lorem",
          copy: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis excepteur sint.",
          image: "photo.jpg",
          cover: true,
          link: "http://google.com",
        },
        {
          title: "Featured Merchant Lorem",
          copy: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis excepteur sint.",
          image: "logo.png",
          cover: false,
          link: "http://google.com",
        },
        {
          title: "Featured Merchant Lorem",
          copy: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis excepteur sint.",
          image: "photo.jpg",
          cover: true,
          link: "http://google.com",
        },
      ],
    },
  },
};

export default intro;
