const cityViewCopy = {
  en: {
    whileTraveling: "While you're traveling in",
    priorityCountries: {
      USA: "Your Discover® Card is accepted by 99% of the places across the country that take credit cards*.",
      JPN: "Many locations take your card, including many popular destinations in Tokyo, Osaka, and Kyoto.",
      PER: "Many locations take your card, including many popular destinations in Lima.",
      ESP: "Many locations take your card, including many top merchants in Barcelona, Madrid, and Seville.",
      URY: "Many locations take your card, including many destinations in Montevideo, Punta del Este, and Colonia del Sacramento.",
      ARG: "A growing number of locations take your card, including many popular destinations in Buenos Aires and Mendoza.",
      CHL: "A growing number of locations take your card, including many popular destinations in Santiago.",
      DEU: "Many locations accept your card, especially those in popular travel destinations.",
      MEX: "A growing number of locations take your card, including many top merchants in Cancun.",
      GBR: "Many locations accept your card, especially those in popular travel destinations.",
      CAN: "A growing number of locations take your card, including many of the most popular merchants in Vancouver and Toronto.",
      ITA: "A growing number of locations take your card, including many popular merchants in Rome.",
      PRT: "A growing number of locations take your card, including many popular merchants in Lisbon.",
      AUS: "A growing number of locations take your card, including top destinations in Sydney, Melbourne, and Brisbane.",
      FRA: "A growing number of locations take your card, including many of the most popular merchants in Paris.",
    },
    acceptanceHeadlines: {
      H: "Many locations accept your card, especially those in popular travel destinations.",
      G: "A growing number of locations accept your card, especially restaurants, hotels, and retailers.",
      J: "A limited number of locations accept your card.",
      "NO COVERAGE":
        "Your card is not yet accepted here—but we are working on it. Our network is expanding all the time.",
    },
    the: "the",
    more: "More",
    travelGuidesToExplore: "travel guides to explore",
    looksLikeYoureIn: "Looks like you're in XX right now",
    wantToSee: "Want to see our Travel Guide for XX?",
    wishYouWereHere: "Wish you were here",
    nextTime:
      "Looks like you're not in XX right now. Next time you're visiting, be sure to check out the Travel Guide and see what's nearby.",
    acceptedPaymentsIn: "Look for these acceptance marks:",
    dgnNetwork:
      "If you have one of these cards, you're a part of the Discover Global Network:",
    nilson: "According to the Feb 2024 issue of the Nilson Report.",
    honolulu: {
      shortGreeting: "Aloha!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Honolulu",
      mobileHeadline:
        "Explore some popular places your card is accepted in Honolulu",
      travelGuideHeadline:
        "The best of Honolulu according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    losangeles: {
      shortGreeting: "Hey!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Los Angeles",
      mobileHeadline:
        "Explore some popular places your card is accepted in Los Angeles",
      travelGuideHeadline:
        "The best of Los Angeles according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    newyork: {
      shortGreeting: "Hi!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in New York City",
      mobileHeadline:
        "Explore some popular places your card is accepted in New York City",
      travelGuideHeadline: "The best of NYC according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    miami: {
      shortGreeting: "Hola!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Miami",
      mobileHeadline:
        "Explore some popular places your card is accepted in Miami",
      travelGuideHeadline:
        "The best of Miami according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    sanjuan: {
      shortGreeting: "Hola!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in San Juan",
      mobileHeadline:
        "Explore some popular places your card is accepted in San Juan",
      travelGuideHeadline:
        "The best of San Juan according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    dubai: {
      shortGreeting: "Salam!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Dubai",
      mobileHeadline:
        "Explore some popular places your card is accepted in Dubai",
      travelGuideHeadline:
        "The best of Dubai according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    tokyo: {
      shortGreeting: "こんにちは",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Tokyo",
      mobileHeadline:
        "Explore some popular places your card is accepted in Tokyo",
      travelGuideHeadline:
        "The best of Tokyo according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    seoul: {
      shortGreeting: "여보!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Seoul",
      mobileHeadline:
        "Explore some popular places your card is accepted in Seoul",
      travelGuideHeadline:
        "The best of Seoul according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    vancouver: {
      shortGreeting: "Hey there!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Vancouver",
      mobileHeadline:
        "Explore some popular places your card is accepted in Vancouver",
      travelGuideHeadline:
        "The best of Vancouver according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    toronto: {
      shortGreeting: "Hey there!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Toronto",
      mobileHeadline:
        "Explore some popular places your card is accepted in Toronto",
      travelGuideHeadline:
        "The best of Toronto according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    bangkok: {
      shortGreeting: "สวัสดี!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Bangkok",
      mobileHeadline:
        "Explore some popular places your card is accepted in Bangkok",
      travelGuideHeadline:
        "The best of Bangkok according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    london: {
      shortGreeting: "Hello!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in London",
      mobileHeadline:
        "Explore some popular places your card is accepted in London",
      travelGuideHeadline:
        "The best of London according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    berlin: {
      shortGreeting: "Hallo!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Berlin",
      mobileHeadline:
        "Explore some popular places your card is accepted in Berlin",
      travelGuideHeadline:
        "The best of Berlin according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    istanbul: {
      shortGreeting: "Merhaba!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Istanbul",
      mobileHeadline:
        "Explore some popular places your card is accepted in Istanbul",
      travelGuideHeadline:
        "The best of Istanbul according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    lisbon: {
      shortGreeting: "Olá!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Lisbon",
      mobileHeadline:
        "Explore some popular places your card is accepted in Lisbon",
      travelGuideHeadline:
        "The best of Lisbon according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    cancun: {
      shortGreeting: "Hola",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Cancun",
      mobileHeadline:
        "Explore some popular places your card is accepted in Cancun",
      travelGuideHeadline:
        "The best of Cancun according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    rome: {
      shortGreeting: "Salvē!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Rome",
      mobileHeadline:
        "Explore some popular places your card is accepted in Rome",
      travelGuideHeadline: "The best of Rome according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    barcelona: {
      shortGreeting: "Hola!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Barcelona",
      mobileHeadline:
        "Explore some popular places your card is accepted in Barcelona",
      travelGuideHeadline:
        "The best of Barcelona according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
      calloutTitle: "Explore More of Barcelona",
      calloutCopy:
        "Find itineraries and travel tips for your Barcelona adventure.",
      calloutCTA: "Let's Go",
    },
    paris: {
      shortGreeting: "Salut!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Paris",
      mobileHeadline:
        "Explore some popular places your card is accepted in Paris",
      travelGuideHeadline:
        "The best of Paris according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    singapore: {
      shortGreeting: "你好",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Singapore",
      mobileHeadline:
        "Explore some popular places your card is accepted in Singapore",
      travelGuideHeadline:
        "The best of Singapore according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    seville: {
      shortGreeting: "Hola",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Seville",
      mobileHeadline:
        "Explore some popular places your card is accepted in Seville",
      travelGuideHeadline:
        "The best of Seville according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    madrid: {
      shortGreeting: "Hola",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Madrid",
      mobileHeadline:
        "Explore some popular places your card is accepted in Madrid",
      travelGuideHeadline:
        "The best of Madrid according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
    doha: {
      shortGreeting: "Salam alaykum!",
      marqueeHeadline:
        "Explore some popular places your card is accepted in Doha",
      mobileHeadline:
        "Explore some popular places your card is accepted in Doha",
      travelGuideHeadline: "The best of Doha according to cardholders like you",
      travelGuideBody:
        "Use the map to explore some of the most popular spots to eat, shop, stay and explore—and this is just the start. Your card is accepted at thousands more places all over town.",
    },
  },
  es: {
    whileTraveling: "Mientras viaja en los EE. UU.",
    priorityCountries: {
      USA: "Su tarjeta Discover® es aceptada por el 99% de los lugares en todo el país que aceptan tarjetas de crédito.*",
      JPN: "Muchas ubicaciones aceptan su tarjeta, incluyendo muchos destinos populares en Tokio, Osaka y Kioto.",
      PER: "Muchas ubicaciones aceptan su tarjeta, incluyendo muchos destinos populares en Lima.",
      ESP: "Muchas ubicaciones aceptan su tarjeta, incluyendo muchos comercios destacados en Barcelona, Madrid y Sevilla.",
      URY: "Muchas ubicaciones aceptan su tarjeta, incluyendo muchos destinos en Montevideo, Punta del Este y Colonia del Sacramento.",
      ARG: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos destinos populares en Buenos Aires y Mendoza.",
      CHL: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos destinos populares en Santiago.",
      DEU: "Muchos ubicaciones aceptan su tarjeta, especialmente aquellos en destinos turísticos populares.",
      MEX: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos comercios destacados en Cancún.",
      GBR: "Muchos ubicaciones aceptan su tarjeta, especialmente aquellos en destinos turísticos populares.",
      CAN: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos de los comercios más populares en Vancouver y Toronto.",
      ITA: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos comercios populares en Roma.",
      PRT: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos comerciantes populares en Lisboa.",
      AUS: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo destinos principales en Sídney, Melbourne y Brisbane.",
      FRA: "Un número creciente de ubicaciones aceptan su tarjeta, incluyendo muchos de los comercios más populares en París.",
    },
    acceptanceHeadlines: {
      H: "Muchas ubicaciones aceptan su tarjeta, especialmente aquellas en destinos de viaje populares.",
      G: "Un número creciente de ubicaciones aceptan su tarjeta, especialmente restaurantes, hoteles y comercios.",
      J: "Un número limitado de ubicaciones aceptan su tarjeta.",
      "NO COVERAGE":
        "Su tarjeta aún no se acepta aquí, pero estamos trabajando en ello. Nuestra red se está expandiendo todo el tiempo.",
    },
    the: "the",
    more: "Más guías de viaje en",
    travelGuidesToExplore: "para explorar",
    looksLikeYoureIn: "Parece que está en XX en este momento",
    wantToSee: "¿Desea ver nuestra Guía de viajes para XX?",
    wishYouWereHere: "Desearía estar aquí",
    nextTime:
      "Parece que no está en XX en este momento. La próxima vez que esté de visita, asegúrese de consultar la Guía de viajes y ver qué hay cerca.",
    acceptedPaymentsIn: "Busca estos signos de aceptación:",
    dgnNetwork:
      "Si tienes una de estas tarjetas, eres parte de la Red Global Discover:",
    nilson: "Según la edición de febrero de 2024 del Informe Nilson.",
    honolulu: {
      shortGreeting: "Aloha!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Honolulu",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Honolulu",
      travelGuideHeadline:
        "Lo mejor de Honolulu según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    losangeles: {
      shortGreeting: "¡Hey!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Los Ángeles",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Los Ángeles",
      travelGuideHeadline:
        "Lo mejor de Los Ángeles según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    newyork: {
      shortGreeting: "¡Hi!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Nueva York",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Nueva York",
      travelGuideHeadline:
        "Lo mejor de Nueva York según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    miami: {
      shortGreeting: "¡Hola!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Miami",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Miami",
      travelGuideHeadline:
        "Lo mejor de Miami según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    sanjuan: {
      shortGreeting: "¡Hola!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en San Juan",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en San Juan",
      travelGuideHeadline:
        "Lo mejor de San Juan según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    dubai: {
      shortGreeting: "¡Salam!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Dubái",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Dubái",
      travelGuideHeadline:
        "Lo mejor de Dubái según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    tokyo: {
      shortGreeting: "こんにちは",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Tokio",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Tokio",
      travelGuideHeadline:
        "Lo mejor de Tokio según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    seoul: {
      shortGreeting: "여보!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Seúl",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Seúl",
      travelGuideHeadline:
        "Lo mejor de Seúl según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    vancouver: {
      shortGreeting: "Hey there!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Vancouver",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Vancouver",
      travelGuideHeadline:
        "Lo mejor de Vancouver según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    toronto: {
      shortGreeting: "Hey there!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Toronto",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Toronto",
      travelGuideHeadline:
        "Lo mejor de Toronto según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    bangkok: {
      shortGreeting: "สวัสดี!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Bangkok",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Bangkok",
      travelGuideHeadline:
        "Lo mejor de Bangkok según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    london: {
      shortGreeting: "¡Hello!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Londres",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Londres",
      travelGuideHeadline:
        "Lo mejor de Londres según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    berlin: {
      shortGreeting: "¡Hallo!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Berlín",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Berlín",
      travelGuideHeadline:
        "Lo mejor de Berlín según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    istanbul: {
      shortGreeting: "Merhaba!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Estambul",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Estambul",
      travelGuideHeadline:
        "Lo mejor de Estambul según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    lisbon: {
      shortGreeting: "¡Olá!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Lisboa",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Lisboa",
      travelGuideHeadline:
        "Lo mejor de Lisboa según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    cancun: {
      shortGreeting: "¡Hola!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Cancún",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Cancún",
      travelGuideHeadline:
        "Lo mejor de Cancún según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    rome: {
      shortGreeting: "¡Salvē!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Roma",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Roma",
      travelGuideHeadline:
        "Lo mejor de Roma según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    barcelona: {
      shortGreeting: "¡Hola!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Barcelona",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Barcelona",
      travelGuideHeadline:
        "Lo mejor de Barcelona según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
      calloutTitle: "Explore más de Barcelona",
      calloutCopy:
        "Encuentre itinerarios y consejos de viaje para su aventura en Barcelona.",
      calloutCTA: "Vamos",
    },
    paris: {
      shortGreeting: "¡Salut!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en París",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en París",
      travelGuideHeadline:
        "Lo mejor de París según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    singapore: {
      shortGreeting: "你好",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Singapur",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Singapur",
      travelGuideHeadline:
        "Lo mejor de Singapur según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    seville: {
      shortGreeting: "¡Hola!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada ens Sevilla",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Sevilla",
      travelGuideHeadline:
        "Lo mejor de Sevilla según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    madrid: {
      shortGreeting: "¡Hola!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Madrid",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Madrid",
      travelGuideHeadline:
        "Lo mejor de Madrid según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
    doha: {
      shortGreeting: "¡Salam alaykum!",
      marqueeHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Doha",
      mobileHeadline:
        "Explora algunos lugares populares donde tu tarjeta es aceptada en Doha",
      travelGuideHeadline:
        "Lo mejor de Doha según los titulares de tarjetas como tú",
      travelGuideBody:
        "Utiliza el mapa para explorar algunos de los lugares más populares para comer, comprar, alojarte y explorar—y esto es solo el comienzo. Tu tarjeta es aceptada en miles de lugares más por toda la ciudad.",
    },
  },
};

export default cityViewCopy;
