import React, { useContext } from "react";
import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material/";

//translation imports
import countryView from "../../util/lang/countryView";
import acceptanceLevels from "../../util/lang/acceptanceLevels";
import countryDrawer from "../../util/lang/countryDrawer";
import cityViewCopy from "../../util/lang/cityViewCopy";
import common from "../../util/lang/common";
import t__ from "../../util/lang/util";
import { MapContext } from "../../contexts";
import CityTiles from "./CityTiles";
import RegionTiles from "./RegionTiles";
import TravelGuidesLogo from "./TravelGuidesLogo";
import Container from "../Layout/Container";
//import AcceptanceMarkBox from "../AcceptanceMarkBox";
import AllCardsBox from "../AllCardsBox";
import AtmBox from "../AtmBox";
import AcceptanceMarks from "../AcceptanceMarks";
import "../../util/theme/animations.css";

const CountryView = () => {
  const theme = useTheme();
  const { cities, selectedCountry, acceptanceData } = useContext(MapContext);
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));

  const getRegion = (string) => {
    if (string.includes("Europe")) return "Europe";
    if (string.includes("Africa" || "Middle East")) return "Africa";
    if (string.includes("Asia")) return "Asia";
    if (string.includes("America")) return "America";
    if (string.includes("Antarctica")) return "Antarctica";
  };

  //user has clicked on a country
  if (selectedCountry?.code) {
    const countryData = acceptanceData.find(
      (a) => a.cca3 === selectedCountry.code
    );

    const hasCreditCoverage = countryData.credit_level !== "NO COVERAGE";
    const region = getRegion(selectedCountry.region);
    const cityGuides = cities.filter((c) => c.country === selectedCountry.code);

    return (
      <>
        <Container pt={10}>
          <Typography variant="h2" component="h1">
            <b>
              {t__(theme.lang, common, "acceptanceIn")}{" "}
              {countryData.cca3 === "USA"
                ? theme.lang === "en"
                  ? "the "
                  : "los "
                : ""}
              {countryData.translated_name ?? countryData.common_name}
            </b>
          </Typography>
          <Box mt={3} mb={10}>
            <Typography>
              {countryDrawer[theme.lang].priorityCountries[countryData.cca3] ??
                acceptanceLevels[theme.lang].headlines[
                  countryData.credit_level
                ]}
            </Typography>
            <Box mt={5}>
              <AcceptanceMarks />
            </Box>
          </Box>

          {cityGuides.length > 0 && (
            <Box>
              {<TravelGuidesLogo />}
              <CityTiles citiesList={cityGuides} />
            </Box>
          )}

          <Box mt={10} mb={10}>
            <Grid container rowSpacing={6} columnSpacing={mdScreen ? 6 : 0}>
              <Grid xs={12} md={6} item style={{ display: "flex" }}>
                <AllCardsBox
                  countryData={countryData}
                  hasCreditCoverage={hasCreditCoverage}
                  region={region}
                />
              </Grid>
              <Grid xs={12} md={6} item style={{ display: "flex" }}>
                <AtmBox countryData={countryData} />
              </Grid>
            </Grid>
          </Box>
          {countryData.cca3 === "USA" && (
            <Box mb={10}>
              <Typography variant="body2" color="textSecondary">
                *{t__(theme.lang, cityViewCopy, "nilson")}
              </Typography>
            </Box>
          )}
        </Container>
      </>
    );
  } else {
    const defaultCities = [
      "Paris",
      "Barcelona",
      "New York",
      "Rome",
      "Istanbul",
      "Cancun",
    ];

    return (
      <>
        <Box
          bgcolor="secondary.light"
          width="100%"
          sx={{ position: "relative", zIndex: 0, marginTop: "-12px" }}
        >
          <AcceptanceMarks
            style={{
              paddingTop: "50px",
              marginBottom: "20px",
              justifyContent: "center",
              marginLeft: "20px",
            }}
          />
          <Box
            maxWidth="650px"
            sx={{ margin: "0 auto 40px", padding: "0 20px" }}
          >
            <Typography
              variant="h2"
              component="h1"
              color="white"
              align="center"
            >
              <b>{t__(theme.lang, countryView, "cardsHeadline")}</b>
            </Typography>
          </Box>

          <Box id="card-rows" pb={"50px"}>
            <Box sx={{ overflow: "hidden", position: "relative" }}>
              <Box
                width={{ xs: "1187px", md: "2374px" }}
                minHeight={{ xs: "100px", md: "200px" }}
              >
                <img
                  srcSet="
                  /Cards11-sm.webp 1187w,
                  /Cards11.webp   2374w
                "
                  src="/Cards11.webp "
                  className="scroll-element primary "
                  alt="Scrolling card images"
                />
                <img
                  srcSet="
                  /Cards11-sm.webp 1187w,
                  /Cards11.webp    2374w
                "
                  src="/Cards11.webp "
                  className="scroll-element secondary max-w-[1187px] md:max-w-[2374px] h-[100px] md:h-[200px]"
                  alt="Scrolling card images"
                />
              </Box>
            </Box>
            <Box sx={{ overflow: "hidden", position: "relative" }}>
              <Box
                width={{ xs: "1175px", md: "2349px" }}
                minHeight={{ xs: "100px", md: "200px" }}
              >
                <img
                  srcSet="/Cards12-sm.webp 1175w,
                  /Cards12.webp    2349w
                "
                  src="/Cards12.webp"
                  className="scroll-element primary2 max-w-[1175px] md:max-w-[2349px] h-[100px] md:h-[200px]"
                  alt="Scrolling card images"
                />
                <img
                  srcSet="/Cards12-sm.webp 1175w,
                  /Cards12.webp    2349w
                "
                  src="/Cards12.webp"
                  className="scroll-element secondary2 max-w-[1175px] md:max-w-[2349px] h-[100px] md:h-[200px]"
                  alt="Scrolling card images"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Container pt={15}>
          <TravelGuidesLogo />

          <Box mt={3} mb={15}>
            <Typography variant="h2" component="h2">
              <b>{t__(theme.lang, countryView, "generalHeadline")}</b>
            </Typography>
            <CityTiles
              citiesList={cities?.filter((c) => defaultCities.includes(c.city))}
            />
          </Box>
        </Container>

        <Box bgcolor="background.light">
          <Container paddingY={15} bgcolor="background.light">
            <Box maxWidth={theme.containerMaxWidth} marginX="auto">
              <Grid container spacing={5}>
                <RegionTiles cities={cities} />
              </Grid>
            </Box>
          </Container>
        </Box>
      </>
    );
  }
};

export default CountryView;
