import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { MapContext } from "../../contexts";
import { Box, useMediaQuery } from "@mui/material/";
import CountryView from "../CountryView";
import CityView from "../CityView";
import Footer from "../Footer";
import smoothscroll from "smoothscroll-polyfill";

const BottomDrawer = ({
  activePin,
  cityView,
  filteredMerchants,
  visibleMerchants,
  map,
  mapLoading,
  setActivePin,
  setFilteredMerchants,
  ...props
}) => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { listView } = useContext(MapContext);

  useEffect(() => {
    if (cityView && !mapLoading) {
      smoothscroll.polyfill();
      setTimeout(function () {
        window.scrollTo({
          top: mdUp ? 50 : 200,
          left: 0,
          behavior: "smooth",
        });
      }, 300);
    }
  }, [cityView, mapLoading, mdUp]);
  return (
    <Box
      position="relative"
      mt={
        listView
          ? mdUp
            ? "281px"
            : "155px"
          : cityView
          ? mdUp
            ? "calc(60vh + 205px)"
            : "calc(60vh + 122px)"
          : "calc(60vh + 62px)"
      }
      minHeight="500px"
      bgcolor="common.white"
      zIndex="1"
      paddingTop={2}
      boxShadow={`0 -2px 6px 0 rgba(0, 0, 0, 0.15)`}
      style={{
        overflowX: "hidden",
      }}
    >
      <Box
        bgcolor="borders.light"
        height={4}
        width={30}
        borderRadius="5px"
        marginX="auto"
        zIndex="1"
        position="relative"
      ></Box>
      {cityView ? (
        <CityView
          activePin={activePin}
          visibleMerchants={visibleMerchants}
          filteredMerchants={filteredMerchants}
          cityView={cityView}
          map={map}
          setActivePin={setActivePin}
          setFilteredMerchants={setFilteredMerchants}
        />
      ) : (
        <CountryView />
      )}
      <Footer />
    </Box>
  );
};

BottomDrawer.propTypes = {
  cityView: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  filteredMerchants: PropTypes.array,
  map: PropTypes.object,
  props: PropTypes.object,
  visibleMerchants: PropTypes.array,
};

export default BottomDrawer;
