const acceptanceLevels = {
  en: {
    tooltip: {
      H: "Many locations accept your card here",
      G: "Growing number of locations accept your card here",
      J: "Limited locations accept your card here",
      "NO COVERAGE": "No locations accept your card here",
    },
    legend: {
      headline: "Global Acceptance",
    },
    modal: {
      headline: "Global Acceptance",
    },
    levels: {
      H: {
        title: "Many Locations",
        desc: "Many locations accept your card, especially those in popular travel destinations.",
      },
      G: {
        title: "Expanding Locations",
        desc: "A growing number of locations accept your card, especially restaurants, hotels, and retailers.",
      },
      J: {
        title: "Limited Locations",
        desc: "A limited number of locations accept your card.",
      },
      "NO COVERAGE": {
        title: "No Locations Yet",
        desc: "Your card is not yet accepted here.",
      },
    },
    headlines: {
      H: "You can use your card at many locations here. Look for these logos at checkout, so you know your card is accepted:",
      G: "You can use your card at a growing number of locations here. Look for these logos at checkout, so you know your card is accepted:",
      J: "You can use your card at select locations here. Look for these logos at checkout, so you know your card is accepted:",
      "NO COVERAGE":
        "Your card is not accepted here—but we are working on it. Our network is expanding all the time. When traveling, you’ll know your card is accepted when you see one of these logos at checkout:",
    },
  },
  es: {
    tooltip: {
      H: "Muchas ubicaciones aceptan su tarjeta aquí",
      G: "Un número creciente de ubicaciones aceptan su tarjeta aquí",
      J: "Un número limitado de ubicaciones aceptan su tarjeta aquí",
      "NO COVERAGE": "Ninguna ubicación acepta su tarjeta aquí",
    },
    legend: {
      headline: "Aceptación global",
    },
    modal: {
      headline: "Aceptación global",
    },
    levels: {
      H: {
        title: "Muchas ubicaciones",
        desc: "Muchas ubicaciones aceptan su tarjeta, especialmente en destinos de viaje populares",
      },
      G: {
        title: "Ubicaciones en Expansión",
        desc: "Un número creciente de ubicaciones aceptan su tarjeta, especialmente restaurantes, hoteles y comercios.",
      },
      J: {
        title: "Ubicaciones Limitadas",
        desc: "Un número limitado de ubicaciones aceptan su tarjeta.",
      },
      "NO COVERAGE": {
        title: "Aún no hay ubicaciones",
        desc: "Su tarjeta aún no es aceptada aquí.",
      },
    },
    headlines: {
      H: "Puedes usar tu tarjeta en muchos lugares aquí. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      G: "Puedes usar tu tarjeta en un número creciente de lugares aquí. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      J: "Puedes usar tu tarjeta en ubicaciones selectas aquí. Busca estos logotipos en el momento de pagar para saber si tu tarjeta es aceptada:",
      "NO COVERAGE":
        "Tu tarjeta no es aceptada aquí, pero estamos trabajando en ello. Nuestra red está en constante expansión. Cuando viajes, sabrás que tu tarjeta es aceptada cuando veas uno de estos logotipos al momento de pagar:",
    },
  },
};

export default acceptanceLevels;
