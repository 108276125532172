// https://material-ui.com/customization/typography/

export const typography = {
  fontSize: 16,
  fontFamily: "DiscoverSans, Roboto, Arial, sans-serif",
  body1: {
    fontSize: "1rem", //16px
    lineHeight: 1.375, //22px
  },
  body2: {
    fontSize: ".875rem", //14px
    lineHeight: 1.36,
  },
  h1: {
    fontSize: "2.625rem", //42px
    lineHeight: 1.07, //48px
    fontWeight: "bold",
    letterSpacing: "-1.18px",
    "@media (min-width:768px)": {
      fontSize: "3.75rem", //60px
      lineHeight: 1.14, //64px
    },
  },
  h2: {
    fontSize: "1.5rem", //24px
    lineHeight: 1.33,
  },
  h2_variant: {
    fontSize: "1rem", //16px
    lineHeight: 1.33,
    "@media (min-width:768px)": {
      fontSize: "1.5rem", //24px
      //lineHeight: 1.14, //64px
    },
  },
  h3: {
    fontSize: "1.25rem", //20px
    lineHeight: 1.25, //38px
  },
  h4: {
    fontSize: "1rem", //16px
    lineHeight: 1.38,
  },
  h5: {
    fontSize: ".75rem", //12px
    lineHeight: 1.375,
  },
  h6: {
    fontSize: ".625rem", //10px
    fontWeight: 400,
  },
  caption: {
    fontSize: ".625rem", //10px
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: "2rem", //32px
    fontWeight: "bold",
  },
  subtitle2: {
    fontSize: "1.5rem", //24px
    lineHeight: 1.29,
    letterSpacing: "normal",
    "@media (min-width:768px)": {
      fontSize: "1.75rem", //28px
    },
  },
  /*demi: {
    fontSize: "1.125rem", //18px
    letterSpacing: "-0.5px"
  }
  small: {
    fontSize: ".6rem" //12px
  }*/
};

export default {
  typography,
};
