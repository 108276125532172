import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { MapContext } from "../../../contexts";
import {
  Box,
  Button,
  ButtonGroup,
  useTheme,
  useMediaQuery,
} from "@mui/material/";
import Container from "../../Layout/Container";
import CATEGORIES from "../../../util/categories";
import cx from "classnames";
import common from "../../../util/lang/common";
import t__ from "../../../util/lang/util";
import { ReactComponent as ListIcon } from "../../Icons/list-view.svg";
import { ReactComponent as MapIcon } from "../../Icons/map-white.svg";
import { ReactComponent as GuideIcon } from "../../Icons/icon-filter-guide-home.svg";
import isDefaultBrand from "../../../util/isDefaultBrand";

const PREFIX = "MapFilters";

const classes = {
  buttonContainer: `${PREFIX}-buttonContainer`,
  gridList: `${PREFIX}-gridList`,
  filterButton: `${PREFIX}-filterButton`,
  edgeButton: `${PREFIX}-edgeButton`,
  withBorder: `${PREFIX}-withBorder`,
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.filterButton}`]: {
    fontSize: 14,
    borderRadius: 20,
    padding: "0px 16px !important",
    height: "36px !important",
    margin: "0px 5px",
    width: "auto !important",
    textTransform: "none",
    color: isDefaultBrand(theme)
      ? theme.palette.text.secondary
      : theme.palette.filterButtonsColor,
    minWidth: "unset",
    borderColor: theme.palette.borders.light,
    [`&.${classes.edgeButton}`]: {
      color: theme.palette.secondary.main,
      "& svg": {
        color: theme.palette.secondary.main,
      },
      "@media (max-width:830px)": {
        margin: "10px 15px 0 15px !important",
      },
      "@media (min-width:769px) and (max-width:830px)": {
        margin: "22px 15px 0 15px !important",
      },
    },
    "&.active": {
      background: isDefaultBrand(theme)
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
      color: theme.palette.common.white,
      "&:hover, &:focus": {
        background: isDefaultBrand(theme)
          ? theme.palette.secondary.main
          : theme.palette.background.filterButtonActive,
      },
    },
    "& svg": {
      color: isDefaultBrand(theme)
        ? theme.palette.text.secondary
        : theme.palette.filterButtonsColor,
    },
    "&.active svg": {
      color: theme.palette.common.white,
    },
    "&:first-child": {
      marginLeft: 0,
    },
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 12,
      height: "32px !important",
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.buttonContainer}`]: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },

  [`& .${classes.gridList}`]: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    overflowX: "auto",
    webkitOverflowSrolling: "touch",
    msOverflowStyle: "-ms-autohiding-srollbar",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "@media (max-width:830px)": {
      marginRight: theme.spacing(24),
      "&.nofilter": {
        marginRight: "-20px",
      },
    },
  },

  [`& .${classes.filterButton}`]: {
    fontSize: 14,
    borderRadius: 20,
    padding: "0px 16px !important",
    height: "36px !important",
    margin: "0px 5px",
    width: "auto !important",
    color: isDefaultBrand(theme)
      ? theme.palette.text.secondary
      : theme.palette.filterButtonsColor,
    minWidth: "unset",
    borderColor: theme.palette.borders.light,
    "&.active": {
      background: isDefaultBrand(theme)
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
      color: theme.palette.common.white,
      "&:hover, &:focus": {
        background: isDefaultBrand(theme)
          ? theme.palette.secondary.main
          : theme.palette.background.filterButtonActive,
      },
    },
    "& svg": {
      color: isDefaultBrand(theme)
        ? theme.palette.text.secondary
        : theme.palette.filterButtonsColor,
    },
    "&.active svg": {
      color: theme.palette.common.white,
    },
    "&:first-child": {
      marginLeft: 0,
    },
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      height: "32px !important",
    },
  },

  [`& .${classes.edgeButton}`]: {
    color: theme.palette.secondary.main,
    "& svg": {
      color: theme.palette.secondary.main,
    },
    "@media (max-width:830px)": {
      margin: "10px 15px 0 15px !important",
    },
    "@media (min-width:769px) and (max-width:830px)": {
      margin: "22px 15px 0 15px !important",
    },
  },

  [`& .${classes.withBorder}`]: {
    border: `1px solid ${theme.palette.borders.light}`,
  },
}));

const MapFilters = ({
  map,
  setFilteredMerchants,
  setMerchantTooltip,
  zoom,
  ...props
}) => {
  const theme = useTheme();

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const specialBreakpoint = useMediaQuery(() => `@media (min-width:830px)`);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { filter, merchants, setFilter, mapLoading, listView, setListView } =
    useContext(MapContext);

  const filterBy = (category) => {
    if (category === filter) {
      setFilteredMerchants(merchants);
      setFilter(null);
      setListView(false);
    } else {
      const results =
        category === "all"
          ? merchants
          : merchants.filter((m) => {
              if (category === "attractions") {
                return m.mcc === "attractions" || m.mcc === "top_attractions";
              } else {
                return m.mcc === category;
              }
            });
      setFilteredMerchants(results);
      setFilter(category);
    }
    setMerchantTooltip(null);
  };

  return (
    !mapLoading && (
      <StyledBox
        height={mdUp ? 76 : 52}
        position="fixed"
        display="flex"
        alignItems="center"
        width="100%"
        top={mdUp ? 205 : 162}
        bgcolor="common.white"
        zIndex={2}
        boxShadow={`0 0px 6px 0 rgba(0, 0, 0, 0.25)`}
      >
        <Container>
          <Box className={classes.buttonContainer}>
            <Box
              className={cx(classes.gridList, !filter && "nofilter")}
              display="flex"
              color="textSecondary"
            >
              <StyledButton
                className={cx(classes.filterButton, !filter ? "active" : "")}
                onClick={() => {
                  setFilteredMerchants(merchants);
                  setFilter(null);
                  setListView(false);
                }}
                aria-label={t__(theme.lang, common, "acceptanceMap")}
                startIcon={<GuideIcon />}
                variant="outlined"
              >
                {t__(theme.lang, common, "guide")}
              </StyledButton>
              {merchants &&
                CATEGORIES.filter((c) => c.mcc !== "top_attractions").map(
                  (c) => {
                    if (
                      merchants.find((m) => m.mcc === c.mcc) ||
                      c.title === "All"
                    ) {
                      return (
                        <StyledButton
                          variant="outlined"
                          key={c.title}
                          startIcon={c.icon}
                          onClick={() => filterBy(c.mcc)}
                          className={cx(
                            classes.filterButton,
                            filter === c.mcc ? "active" : ""
                          )}
                        >
                          {t__(
                            theme.lang,
                            common,
                            `categories.${c.title.toLowerCase()}`
                          )}
                        </StyledButton>
                      );
                    } else {
                      return null;
                    }
                  }
                )}
            </Box>
            {filter && (
              <Box
                position={specialBreakpoint ? "" : "absolute"}
                right={0}
                top={0}
                height={specialBreakpoint ? "auto" : mdUp ? 76 : 52}
                bgcolor="common.white"
                style={
                  specialBreakpoint
                    ? {}
                    : { boxShadow: "-6px 0px 10px 0px rgba(0, 0, 0, 0.25)" }
                }
              >
                {lgUp ? (
                  <ButtonGroup>
                    <StyledButton
                      className={cx(
                        classes.filterButton,
                        classes.edgeButton,
                        !listView ? "active" : ""
                      )}
                      startIcon={<MapIcon />}
                      style={{ marginRight: 0 }}
                      onClick={() => setListView(!listView)}
                    >
                      {t__(theme.lang, common, "map")}
                    </StyledButton>
                    <StyledButton
                      className={cx(
                        classes.filterButton,
                        classes.edgeButton,
                        listView ? "active" : ""
                      )}
                      startIcon={<ListIcon />}
                      onClick={() => setListView(!listView)}
                    >
                      {t__(theme.lang, common, "list")}
                    </StyledButton>
                  </ButtonGroup>
                ) : (
                  <StyledButton
                    className={cx(
                      classes.filterButton,
                      classes.edgeButton,
                      classes.withBorder
                    )}
                    startIcon={listView ? <MapIcon /> : <ListIcon />}
                    style={{ marginRight: 0 }}
                    onClick={() => setListView(!listView)}
                  >
                    {listView
                      ? t__(theme.lang, common, "map")
                      : t__(theme.lang, common, "list")}
                  </StyledButton>
                )}
              </Box>
            )}
          </Box>
        </Container>
      </StyledBox>
    )
  );
};

MapFilters.propTypes = {
  map: PropTypes.object.isRequired,
  setFilteredMerchants: PropTypes.func.isRequired,
  setMerchantTooltip: PropTypes.func.isRequired,
  props: PropTypes.object,
  zoom: PropTypes.number,
};

export default MapFilters;
