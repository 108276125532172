import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Box,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material/";
import { MapContext } from "../../../contexts";
import acceptanceLevels from "../../../util/lang/acceptanceLevels";
import countryDrawer from "../../../util/lang/countryDrawer";
import CityTile from "../../CountryView/CityTile";
import { useHistory } from "react-router-dom";
import t__ from "../../../util/lang/util";
import cityViewCopy from "../../../util/lang/cityViewCopy";
import isDefaultBrand from "../../../util/isDefaultBrand";
import trackLocationChange from "../../../util/trackLocationChange";

const PREFIX = "CountryCallout";

const classes = {
  calloutList: `${PREFIX}-calloutList`,
  calloutListItem: `${PREFIX}-calloutListItem`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.calloutList}`]: {
    marginTop: theme.spacing(4),
  },

  [`& .${classes.calloutListItem}`]: {
    background: theme.palette.common.white,
    borderRadius: theme.spacing(5),
    marginBottom: theme.spacing(5),
    height: theme.spacing(10),
    "&:last-child": {
      marginBottom: 0,
    },
    "&:hover": {
      background: theme.palette.background.light,
    },
  },
}));

const CountryCallout = ({ countryData, setFilteredMerchants, ...props }) => {
  const history = useHistory();
  const theme = useTheme();
  const {
    cities,
    cityView,
    locationShared,
    setLocationShared,
    setMapLoading,
    setCityView,
    setFilter,
    setMerchants,
  } = useContext(MapContext);
  const fullScreen = useMediaQuery(theme.breakpoints.up("md"));
  const under992 = useMediaQuery(theme.breakpoints.down("lg"));
  const otherCityGuides = cities.filter(
    (c) => c.country === countryData.cca3 && c.city !== cityView
  );
  return (
    <StyledBox
      borderRadius={theme.spacing(3)}
      bgcolor={
        isDefaultBrand(theme)
          ? "secondary.light"
          : theme.palette.brand.primaryBg
      }
      color={isDefaultBrand(theme) ? "common.white" : theme.palette.brand.text}
      width="100%"
      padding={fullScreen ? theme.spacing(10) : theme.spacing(5)}
    >
      <Box mb={3}>
        <Typography variant={fullScreen ? "h3" : "body1"}>
          <b>
            {t__(theme.lang, cityViewCopy, "whileTraveling")}{" "}
            {countryData.cca3 === "USA"
              ? `${t__(theme.lang, cityViewCopy, "the")} U.S.`
              : countryData.common_name}
          </b>
        </Typography>
      </Box>
      <Box mt={3} mb={4}>
        <Typography>
          {cityViewCopy[theme.lang].priorityCountries[countryData.cca3] ??
            cityViewCopy[theme.lang].acceptanceHeadlines[
              countryData.credit_level
            ]}
        </Typography>
      </Box>

      {otherCityGuides.length > 0 && (
        <>
          <Typography>
            <b>
              {t__(theme.lang, cityViewCopy, "more")}{" "}
              {countryData.cca3 === "USA" ? "U.S." : countryData.common_name}{" "}
              {t__(theme.lang, cityViewCopy, "travelGuidesToExplore")}
            </b>
          </Typography>
          {under992 ? (
            <List className={classes.calloutList}>
              {otherCityGuides.splice(0, 3).map((o, ind) => {
                return (
                  <ListItem
                    button
                    disableGutters
                    key={o.city + ind}
                    className={classes.calloutListItem}
                    onClick={(e) => {
                      if (locationShared.length > 0)
                        locationShared[0].setMap(null);
                      setLocationShared([]);
                      e.preventDefault();
                      window.scrollTo(0, 0);
                      setMapLoading(true);
                      setMerchants(null);
                      setFilter(null);
                      setFilteredMerchants(null);
                      setCityView(o.city);
                      isDefaultBrand(theme)
                        ? history.push(`/guides/${o.route}`)
                        : history.push(
                            `/guides/${o.route}?brand=${theme.brand}`
                          );
                      trackLocationChange();
                    }}
                  >
                    <Box
                      component="span"
                      borderRadius={theme.spacing(10)}
                      border={1}
                      borderColor="common.white"
                      width={theme.spacing(10)}
                      height={theme.spacing(10)}
                      display="block"
                      bgcolor="background.light"
                      marginRight={theme.spacing(4)}
                      style={{
                        background: `url(${o.img}) 0 0 no-repeat`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                      }}
                    ></Box>
                    <Typography color="textPrimary">
                      <b>{o.city}</b>
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Box display="flex" marginLeft={"-8px"} marginTop={4} width="100%">
              {otherCityGuides.splice(0, 3).map((o) => (
                <Box width={1 / 3} key={o.city}>
                  <CityTile
                    city={o}
                    height={72}
                    onClick={(e) => {
                      if (locationShared.length > 0)
                        locationShared[0].setMap(null);
                      setLocationShared([]);
                      e.preventDefault();
                      window.scrollTo(0, 0);
                      setMapLoading(true);
                      setMerchants(null);
                      setFilter(null);
                      setFilteredMerchants(null);
                      setCityView(o.city);
                      isDefaultBrand(theme)
                        ? history.push(`/guides/${o.route}`)
                        : history.push(
                            `/guides/${o.route}?brand=${theme.brand}`
                          );
                      trackLocationChange();
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
        </>
      )}
    </StyledBox>
  );
};

CountryCallout.propTypes = {
  countryData: PropTypes.object.isRequired,
  props: PropTypes.object,
};

export default CountryCallout;
