const intro = {
  en: {
    merchants: (
      <>
        Merchants listed on the site are validated based on internal data on a
        semi-annual basis and are subject to change during such validation
        periods. Please note that merchant information may contain inaccuracies
        or errors which may be the result of information provided by third
        parties.
      </>
    ),
    copyright: "DFS Services LLC",
    twitterMessage: "Connect with us on Twitter",
    linkedInMessage: "Connect with us on LinkedIn",
    youtubeMessage: "Visit us on YouTube",
    facebookMessage: "Connect with us on Facebook",
  },
  es: {
    merchants: (
      <>
        Los comercios que figuran en el sitio se validan en función de los datos
        internos de forma semestral y están sujetos a cambios durante dichos
        períodos de validación. Tenga en cuenta que la información de los
        comercios puede no ser precisos o contener errores que pueden ser el
        resultado de la información proporcionada por terceros.
      </>
    ),
    copyright: "DFS Services LLC",
    twitterMessage: "Conéctese con nosotros en Twitter",
    linkedInMessage: "Conéctese con nosotros en LinkedIn",
    youtubeMessage: "Conéctese con nosotros en YouTube",
    facebookMessage: "Conéctese con nosotros en Facebook",
  },
};

export default intro;
